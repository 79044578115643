import {
  ApiResponse,
  ChenhLechVatTuBienBanResponse,
  IPhieuXuatKhoResponseBase,
  PaginationResponseBase,
  ReceiptBaseResponse
} from './../interfaces/ApiResponses';
import { apiServiceInstance } from '@/core/helpers/utils';
import type { ICreateOrUpdatePhieuXuatKhac, IVatTuSelectedInKho } from '../interfaces/Models';
import { useDateTime } from '@/core/hooks/use-date-time-hook';

const { moment } = useDateTime();

export default class PhieuXuatKhoService {
  static createFromBBGT(
    id_bien_ban: number,
    so_bien_ban: string,
    id_nguoi_nhan: number,
    selectedSuppliesInWarehouse: IVatTuSelectedInKho[]
  ): Promise<{
    data: ApiResponse<{
      id_nguoi_nhan: number;
      so_bien_ban: string;
      so_phieu: string;
    }>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';

    const bodyFormData = new FormData();

    bodyFormData.append('phieuXuatKho[so_bien_ban]', so_bien_ban);

    bodyFormData.append(
      'phieuXuatKho[id_nguoi_nhan]',
      id_nguoi_nhan.toString()
    );

    selectedSuppliesInWarehouse.forEach((item) => {
      item.kho_arr.forEach((w) => {
        bodyFormData.append(
          `phieuXuatKho[vatTuKho][${w.kho_id}][${item.id_vat_tu}][so_luong]`,
          w.da_lay.toString()
        );
      });
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/phieu-xuat-kho/bien-ban-giai-the/${id_bien_ban}/tao-moi`,
      bodyFormData
    );
  }

  static createOrUpdatePhieuXuatKhac(params: ICreateOrUpdatePhieuXuatKhac): Promise<{
    data: ApiResponse<IPhieuXuatKhoResponseBase>
  }> {
    const {
      id_nguoi_nhan,
      id_phieu_xuat,
      id_kho,
      ly_do,
      ma_ct_no,
      ma_tk_no,
      ngay,
      so_bien_ban,
      ten_tk_no,
      vat_tu_items,
    } = params;
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';

    const bodyFormData = new FormData();

    const isEditing = !!id_phieu_xuat;

    bodyFormData.append('phieuXuat[so_bien_ban]', so_bien_ban);

    bodyFormData.append('phieuXuat[id_nguoi_nhan]', id_nguoi_nhan.toString());

    bodyFormData.append('phieuXuat[ly_do]', ly_do);

    bodyFormData.append('phieuXuat[ngay]', moment(ngay).format('YYYY-MM-DD'));

    vat_tu_items.forEach(({ id, pivot: { don_gia, so_luong } }) => {
      bodyFormData.append(`vatTu[${id}][so_luong]`, so_luong.toString());

      bodyFormData.append(`vatTu[${id}][don_gia]`, don_gia ? don_gia.toString() : '0');
    });

    bodyFormData.append('phieuXuat[tk_no][ma_tk]', ma_tk_no);

    bodyFormData.append('phieuXuat[tk_no][ten_tk]', ten_tk_no);

    if (ma_ct_no) {
      bodyFormData.append('phieuXuat[tk_no][ma_ct]', ma_ct_no);
    }

    const uri = isEditing ? `/api/v1/phieu-xuat-kho/cap-nhat/${id_phieu_xuat}` : `/api/v1/phieu-xuat-kho/kho/${id_kho}/tao-moi`;

    return apiServiceInstance().vueInstance.axios.post(
      uri,
      bodyFormData
    );
  }

  static listByReport(id: number): Promise<{
    data: ApiResponse<ReceiptBaseResponse[]>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/phieu-xuat-kho/danh-sach/bien-ban-giai-the/${id}`
    );
  }

  static get(receiptId: number): Promise<{
    data: ApiResponse<ReceiptBaseResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/phieu-xuat-kho/chi-tiet/${receiptId}`
    );
  }

  static getListByKhoId({
    current_page,
    per_page,
    id_kho,
    search,
  }): Promise<{
    data: ApiResponse<PaginationResponseBase<IPhieuXuatKhoResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/phieu-xuat-kho/danh-sach/kho/${id_kho}`, {
        params: {
          page: current_page ?? 1,
          ...(search && {
            s: search,
          }),
        },
        headers: {
          perpage: per_page ?? 1000,
        }
      }
    );
  }

  static updateNgayHoanThanh({
    id,
    ngay_hoan_thanh,
  }: {
    id: number;
    ngay_hoan_thanh: Date;
  }) {
    const dateStr = moment(ngay_hoan_thanh).format('YYYY-MM-DD');

    apiServiceInstance().vueInstance.axios.defaults.headers.common[
        "Content-Type"
        ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('extra[ngay_hoan_thanh]', dateStr);

    return apiServiceInstance().vueInstance.axios.post(
        `/api/v1/phieu-xuat-kho/update-ngay-hoan-thanh/${id}`,
        bodyFormData,
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/phieu-xuat-kho/xoa/${id}`,
    );
  }

  static vaildateVatTuXuatKho({
    id,
    exclude_id_phieu_xuat_items,
  }: {
    id: number,
    exclude_id_phieu_xuat_items?: number[],
  }): Promise<{
    data: ApiResponse<ChenhLechVatTuBienBanResponse[]>
  }> {
    let query = '';

    if (exclude_id_phieu_xuat_items?.length) {
      exclude_id_phieu_xuat_items.forEach((phieuXuatId) => {
        query = `${query}&exclude_id_phieu_xuat[]=${phieuXuatId}`;
      })
    }

    const baseUri = `/api/v1/phieu-xuat-kho/validate-dinh-muc-xuat-kho/${id}`;

    const uri = exclude_id_phieu_xuat_items?.length ? `${baseUri}?${query}` : baseUri;

    return apiServiceInstance().vueInstance.axios.get(uri);
  }
}
